<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card style="min-width: 1000px">
      <el-row>
        <el-col class="">
          <el-button type="primary" size="medium" class="demo-input-label" style="width: 100px" icon="el-icon-plus" @click="emunEdit('1', {})">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="menuList" style="width: 100%" row-key="id" border lazy load :tree-props="{ children: 'children' }">
        <el-table-column prop="name" label="名称" sortable width="180"></el-table-column>
        <el-table-column prop="path" label="路径" sortable width="130"></el-table-column>
        <el-table-column prop="type" label="菜单" width="100">
          <template #default="scope">
            <el-tag size="mini" v-if="scope.row.type === 'M'">目录</el-tag>
            <el-tag type="success" size="mini" v-else-if="scope.row.type === 'C'">菜单</el-tag>
            <el-tag type="warning" size="mini" v-else>按钮</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="排序" width="180" sortable>
          <template #default="scope">
            <el-tag size="mini" v-if="scope.row.level === 1">一级</el-tag>
            <el-tag type="success" size="mini" v-else-if="scope.row.level === 2">二级</el-tag>
            <el-tag type="warning" size="mini" v-else>三级</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="cat_id" label="是否有效" sortable>
          <template #default="scope">
            <el-switch v-model="scope.row.status" disabled active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" @change="switchChange"></el-switch>
          </template>
        </el-table-column>

        <!-- 操作 -->
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="emunEdit('2', scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteEmun(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加分类的对话框 -->
    <el-dialog :title="updateMenuType == 1 ? '添加菜单' : '编辑菜单'" :visible.sync="addDialogVisible">
      <el-dialog width="30%" title="内层 Dialog" v-model="innerVisible" append-to-body></el-dialog>
      <el-form :model="addmenuForm" ref="updatemenuRef" label-width="100px" class="demo-ruleForm" :rules="addmenurules">
        <el-form-item label="菜单名称" prop="name">
          <el-input v-model="addmenuForm.name" placeholder="请输入菜单中文名称"></el-input>
        </el-form-item>
        <el-form-item label="父菜单ID" prop="parentIds">
          <el-cascader
            ref="demoCascader"
            v-model="addmenuForm.parentIds"
            show-all-levels="false"
            :options="cascaderMenuList"
            :props="{ checkStrictly: true, label: 'name', value: 'id' }"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="菜单英文名称" prop="code">
          <el-input v-model="addmenuForm.code" placeholder="请输入菜单英文名称"></el-input>
        </el-form-item>
        <el-form-item label="菜单等级">
          <el-input v-model="addmenuForm.parentIds.length" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="显示顺序">
          <el-input v-model="addmenuForm.orderNum" type="number" placeholder="请输入显示顺序"></el-input>
        </el-form-item>
        <el-form-item label="路由地址">
          <el-input v-model="addmenuForm.path" placeholder="请输入路由地址"></el-input>
        </el-form-item>
        <el-form-item label="组件路径">
          <el-input v-model="addmenuForm.component" placeholder="请输入组件路径"></el-input>
        </el-form-item>
        <el-form-item label="外链地址">
          <el-input v-model="addmenuForm.isFrame" placeholder="请输入外部链接"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-radio v-model="addmenuForm.type" label="M">目录</el-radio>
          <el-radio v-model="addmenuForm.type" label="C">菜单</el-radio>
          <el-radio v-model="addmenuForm.type" :label="F">按钮</el-radio>
        </el-form-item>

        <el-form-item label="显示状态">
          <el-radio v-model="addmenuForm.visible" label="1">显示</el-radio>
          <el-radio v-model="addmenuForm.visible" label="0">隐藏</el-radio>
        </el-form-item>
        <el-form-item label="菜单状态">
          <el-radio v-model="addmenuForm.status" label="1">显示</el-radio>
          <el-radio v-model="addmenuForm.status" label="0">隐藏</el-radio>
        </el-form-item>
        <el-form-item label="权限字符串">
          <el-input v-model="addmenuForm.perms"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标">
          <el-input v-model="addmenuForm.icon"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="addmenuForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="是否缓存">
          <el-radio v-model="addmenuForm.isCache" label="1">是</el-radio>
          <el-radio v-model="addmenuForm.isCache" label="0">否</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">提交</el-button>
          <el-button @click="addDialogVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
const addParams = {
  name: '', // 菜单名称
  parentId: '',
  parentIds: ['1'], // 父菜单ID
  code: '', //菜单英文名称
  level: '1', //菜单等级
  orderNum: '', //显示顺序
  path: '', //路由地址
  component: '', //组件路径
  isFrame: '', //外链地址
  type: 'M', //类型
  visible: '1',
  status: '1',
  perms: '',
  icon: '',
  remark: '',
  isCache: '0',
}
export default {
  data() {
    // 验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/

      if (regEmail.test(value)) {
        // 合法的邮箱
        return cb()
      }

      cb(new Error('请输入合法的邮箱'))
    }

    // 验证手机号的规则
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/

      if (regMobile.test(value)) {
        return cb()
      }

      cb(new Error('请输入合法的手机号'))
    }
    return {
      params: { pageNum: 1, pageSize: 5, keyWord: '' },
      menuList: [],
      addDialogVisible: false,
      addmenuForm: { ...addParams },
      cascaderMenuList: [
        {
          id: '1',
          level: 0,
          name: '管理系统',
          params: {},
          parentId: '1',
          children: [],
        },
      ],
      addmenurules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        parentIds: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
        code: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
      },
      // 添加表单的验证规则对象
      addFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 10, message: '用户名的长度在3~10个字符之间', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '用户名的长度在6~15个字符之间', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' },
        ],
      },
    }
  },

  mounted() {
    this.findList()
  },

  methods: {
    async findList() {
      const res = await this.$API.menu.get()
      if (res.status == 200) {
        this.cascaderMenuList[0].children = res.data
        this.menuList = res.data
        console.log('this.menuList', this.menuList)
        this.$store.dispatch('menuList', res.data)
      }
    },

    //点击编辑
    emunEdit(type, item) {
      console.log('itemitem', item)
      this.addDialogVisible = true
      this.updateMenuType = type
      if (type == 1) {
        return (this.addmenuForm = { ...addParams })
      }
      let params = { ...item }
      if (params.parentIds) {
        params.parentIds = params.parentIds.split(',')
      }
      this.addmenuForm = { ...params }
    },
    async deleteEmun(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch(async (err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }

      const res = await this.$API.menu.delete({ id: id })
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.data)
      this.findList()
    },
    async addMenu() {
      const params = this.addmenuForm
      params.parentId = params.parentIds[params.parentIds.length - 1]
      params.level = params.parentIds.length.toString()
      if (this.addmenuForm.parentIds) {
        this.addmenuForm.parentIds = this.addmenuForm.parentIds.join(',')
      }
      const res = await this.$API.menu.add(this.addmenuForm)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.data)
      this.findList()
      this.addDialogVisible = false
    },

    submitForm() {
      this.$refs.updatemenuRef.validate((valid) => {
        if (!valid) return
        if (this.updateMenuType == 1) {
          this.addMenu()
        } else {
          this.updateMenu()
        }
      })
    },
    // 编辑菜单
    async updateMenu() {
      if ((this.addmenuForm.parentIds && this.addmenuForm.parentIds, length > 0)) {
        this.addmenuForm.parentIds = this.addmenuForm.parentIds.join(',')
      } else {
        this.addmenuForm.parentIds = this.addmenuForm.parentIds[0] + ''
      }
      console.log('updateMenu --参数', this.addmenuForm)
      // return
      const res = await this.$API.menu.update(this.addmenuForm)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.data)
      this.findList()
      this.addDialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
